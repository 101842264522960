angular.module('main')
        .directive('echartSummary', function () {
            return {
                scope: {
                    indicator: '=',
                    id: '=',
                    model: '='
                },
                restrict: 'E',
                replace: true,
                controller: controller,
                controllerAs: 'vm',
                bindToController: true
            };

            function controller($scope) {
                var vm = this;
                $scope.$on('modelChanged', function(event, nuevoModel) {
                      valor = source();
                      var compararFiltro = function(filtro, dataValue) {
                     
                        if (filtro === "Todos" || dataValue == filtro) {
                          return true;
                        }else{
                          return false;
                        }
                        
                      };
                      
                      var resultado = valor.filter(function(dato) {
                        
                        if (dato.name === 'Total Consumo') {
                          return true;
                        }
                         return compararFiltro(vm.model.sector, dato.data["f-sector"]) &&
                          compararFiltro(vm.model.criticality, dato.data["f-criticality"]) &&
                          compararFiltro(vm.model.industry, dato.data["f-industry"]) &&
                          compararFiltro(vm.model.numberOfEmployees, dato.data["f-numberOfEmployees"]) &&
                          compararFiltro(vm.model.pais, dato.data["f-paises"]) &&
                          compararFiltro(vm.model.ciudad, dato.data["f-countries"]) &&
                          compararFiltro(vm.model.size, dato.data["f-classificationBySize"]) &&
                          compararFiltro(vm.model.type, dato.data["f-type"]); 
      
                      });
               
                      var chartDom = document.getElementById('echart-' + vm.id);
                      var myChart = echarts.getInstanceByDom(chartDom);

                      // Paso 1: Destruir la instancia del gráfico
                      if (myChart) {
                        myChart.dispose();
                      }var chartDom = document.getElementById('echart-' + vm.id);
                      var myChart = echarts.getInstanceByDom(chartDom);

                      // Paso 1: Destruir la instancia del gráfico
                      if (myChart) {
                        myChart.dispose();
                      }

                     myChart = echarts.init(chartDom);

                      myChart.setOption(
                        {
                          
                          tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                              type: 'cross',
                              crossStyle: {
                                color: '#999'
                              }
                            }
                          },
                          legend: {},
                          toolbox: {
                            show: true,
                            feature: {
                              dataZoom: {
                                yAxisIndex: 'none'
                              },
                              dataView: { readOnly: false },
                              magicType: { type: ['line', 'bar'] },
                              restore: {},
                              saveAsImage: {}
                            },
                            orient: 'vertical',
                            right: 0,
                            top: 'center'
                          },
                          xAxis: {
                            type: 'category',
                            axisPointer: {
                              type: 'shadow'
                            },
                            data: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul','Ago', 'Set', 'Oct','Nov', 'Dec'],
                          },
                          yAxis: {
                            type: 'value',
                            axisLabel: {
                              formatter: '{value}'
                            }
                          },
                          series: resultado
                        } 
                        );
        
                        window.onresize = function() {
                          myChart.resize();
                        };

              });
             

                const labelName = [];
                const labelTotal = [];
                const indicators = [];
                const indicatorsTotal = [];

                if (vm.indicator.showGraphGroup) {
                  document.getElementById('chart-'+vm.id).style.display = "block";
                 }else{
                  document.getElementById('chart-'+vm.id).style.display = "none";
                 }
              var chartDom = document.getElementById('echart-'+vm.id);
              var  myChart = echarts.init(chartDom);

              const subTotal = [];
   
              vm.indicator.answer.subAnswers.forEach(function(response) {
                const series = [];
            
                subTotal[0] =   typeof subTotal[0] === 'undefined' ? 0 + parseInt(defaultValue(response.january)) : subTotal[0] + parseInt(defaultValue(response.january));
                subTotal[1] = typeof subTotal[1] === 'undefined' ? 0 + parseInt(defaultValue(response.february)) : subTotal[1] + parseInt(defaultValue(response.february));
                subTotal[2] = typeof subTotal[2] === 'undefined' ? 0 + parseInt(defaultValue(response.march)) : subTotal[2] + parseInt(defaultValue(response.march));
                subTotal[3] = typeof subTotal[3] === 'undefined' ? 0 + parseInt(defaultValue(response.april)) : subTotal[3] + parseInt(defaultValue(response.april));
                subTotal[4] =typeof subTotal[4] === 'undefined' ? 0 + parseInt(defaultValue(response.may)) : subTotal[4] + parseInt(defaultValue(response.may));
                subTotal[5] = typeof subTotal[5] === 'undefined' ? 0 + parseInt(defaultValue(response.june)) : subTotal[5] + parseInt(defaultValue(response.june));
                subTotal[6] =  typeof subTotal[6] === 'undefined' ? 0 + parseInt(defaultValue(response.july)) : subTotal[6] + parseInt(defaultValue(response.july));
                subTotal[7] = typeof subTotal[7] === 'undefined' ? 0 + parseInt(defaultValue(response.august)) : subTotal[7] + parseInt(defaultValue(response.august));
                subTotal[8] = typeof subTotal[8] === 'undefined' ? 0 + parseInt(defaultValue(response.september)) : subTotal[8] + parseInt(defaultValue(response.september));
                subTotal[9] = typeof subTotal[9] === 'undefined' ? 0 + parseInt(defaultValue(response.october)) : subTotal[9] + parseInt(defaultValue(response.october));
                subTotal[10] = typeof subTotal[10] === 'undefined' ? 0 + parseInt(defaultValue(response.november)) : subTotal[10] + parseInt(defaultValue(response.november));
                subTotal[11] = typeof subTotal[11] === 'undefined' ? 0 + parseInt(defaultValue(response.december)) : subTotal[11] + parseInt(defaultValue(response.december));
                 series.push(defaultValue(response.january));
                 series.push(defaultValue(response.february));
                 series.push(defaultValue(response.march));
                 series.push(defaultValue(response.april));
                 series.push(defaultValue(response.may));
                 series.push(defaultValue(response.june));
                 series.push(defaultValue(response.july));
                 series.push(defaultValue(response.august));
                 series.push(defaultValue(response.september));
                 series.push(defaultValue(response.october));
                 series.push(defaultValue(response.november));
                 series.push(defaultValue(response.december));

                 series['f-type'] = response.type;
                 series['f-classificationBySize'] = response.classificationBySize;
                 series['f-countries'] = response.countries;
                 series['f-criticality'] = response.criticality;
                 series['f-numberOfEmployees'] = response.numberOfEmployees;
                 series['f-paises'] = response.paises;
                 series['f-sector'] = response.sector;
                 series['f-industry'] = response.industry;
         
                 indicators.push(series);
                 labelName.push(response.office);
              });
              indicatorsTotal.push(subTotal);
              labelTotal.push('Total Consumo');

              


              valor = source();      
              
              myChart.setOption(
               {
                 
                 tooltip: {
                   trigger: 'axis',
                   axisPointer: {
                     type: 'cross',
                     crossStyle: {
                       color: '#999'
                     }
                   }
                 },
                 legend: {},
                 toolbox: {
                   show: true,
                   feature: {
                     dataZoom: {
                       yAxisIndex: 'none'
                     },
                     dataView: { readOnly: false },
                     magicType: { type: ['line', 'bar'] },
                     restore: {},
                     saveAsImage: {}
                   },
                   orient: 'vertical',
                   right: 0,
                   top: 'center'
                 },
                 xAxis: {
                   type: 'category',
                   axisPointer: {
                     type: 'shadow'
                   },
                   data: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul','Ago', 'Set', 'Oct','Nov', 'Dec'],
                 },
                 yAxis: {
                   type: 'value',
                   axisLabel: {
                     formatter: '{value}'
                   }
                 },
                 series: valor
               } 
               );

               window.onresize = function() {
                 myChart.resize();
               };
             
                  

              function source() {
                  const parent = [];
                

                  for (var i = 0; i < labelName.length; i++) {
                      parent.push({
                          name: labelName[i],
                          type: 'bar',
                          tooltip: {
                            valueFormatter: function (value) {
                              return value + ' Sub';
                            }
                          },
                          data: indicators[i]
                  });
                }
                for (var i = 0; i < labelTotal.length; i++) {             
                    parent.push({
                        name: labelTotal[i],
                        type: 'line',
                        markPoint: {
                          data: [
                            { type: 'max', name: 'Max' },
                            { type: 'min', name: 'Min' }
                          ]
                        },
                        tooltip: {
                          valueFormatter: function (value) {
                            return value + ' Total';
                          }
                        },
                        data: indicatorsTotal[i]
                });     
                }
                  return parent; 
              }






              function defaultValue(params) {
                var valor = 0;
                typeof params === 'undefined' ? 0 : valor =params ;

                return valor;
               }
            
            }
        });