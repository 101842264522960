(function () {
    'use strict';

    angular
            .module('main')
            .controller('OfficeEditController', OfficeEditController);

    function OfficeEditController($stateParams, countryService, currencyService, officeService, user) {
        var vm = this;
        init();

        function init() {
            vm.save = save;
            loadCountries();
            loadPaises();
            loadCurrencies();
            loadOffice();
        }
        
        function save() {
            officeService.save(vm.office)
                    .then(success)
                    .catch(error);

            function success() {
                vm.success = true;
            }

            function error(error) {
                vm.error = true;
                console.log(error);
            }
        }

        function loadOffice() {
            officeService.find($stateParams.id)
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.office = response;
                if(typeof  vm.office.paises != 'undefined'){
                    loadCiudades(vm.office.paises.codigo);
                }
               
                
            }

            function error(error) {
                console.log(error);
            }
        }

        function loadCountries() {
            countryService.findAll()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.countries = response;
            }

            function error(error) {
                console.log(error);
            }
        }
        function loadPaises() {
            countryService.findAllP()
                    .then(success)
                    .catch(error);

            function success(response) {
              
                vm.paises = response;
            }

            function error(error) {
                console.log(error);
            }
        }
        function loadCiudades(code) {
            countryService.findAllC(code)
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.ciudades = response;
            }

            function error(error) {
                console.log(error);
            }
        }
        
        function loadCurrencies() {
            currencyService.findAll()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.currencies = response;
            }

            function error(error) {
                console.log(error);
            }
        }
    }

})();

