(function () {
    'use strict';

    angular
            .module('main')
            .service('answerService', answerService);

    function answerService($q, $http, companyCertification,routeService) {
        var months = [
            'april',
            'august',
            'december',
            'february',
            'january',
            'july',
            'june',
            'march',
            'may',
            'november',
            'october',
            'september'
        ];

        return {
            save: save,
            remove: remove,
            isMonthlyCompleted: isMonthlyCompleted,
            quantityMonthly: quantityMonthly,
            saveMonthlyEvidence: saveMonthlyEvidence,
            getMonthlyEvidence: getMonthlyEvidence,
            removeMonthlyEvidence: removeMonthlyEvidence,
            downloadMonthRecord: downloadMonthRecord,
            updateMonthlyEvidence: updateMonthlyEvidence
        };

        function save(indicator) {
            var deferred = $q.defer();
            var url = '/api/answers/';

            switch (indicator.type) {
                case 'INFORMATIVE':
                    url+= 'informative';
                    break;
                case 'SHORT_TEXT':
                    url+= 'text';
                    break;
                case 'LONG_TEXT':
                    url+= 'text';
                    break;
                case 'SORTABLE':
                    url+= 'sortable';
                    break;
                case 'UNIQUE_SELECTION':
                    url+= 'unique-selection';
                    break;
                case 'UNIQUE_SELECTION_EXPANDED':
                    url+= 'unique-selection';
                    break;
                case 'MULTIPLE_SELECTION':
                    url+= 'multiple-selection';
                    break;
                case 'FORM':
                    url+= 'form';
                    break;
                case 'MONTHLY_FORM':
                    url+= 'monthly-form';
                    break;
                case 'MONTHLY_GROUP':
                    url+= 'monthly-group';
                    break;
                case 'SUMMARY':
                    deferred.resolve();
                    return deferred.promise;
                    break;
                default :
                    return deferred.promise;
                    break;
            }
            
            indicator.answer.indicator = { id:indicator.id };
            indicator.answer.companyCertification = companyCertification;
            $http.post(url, indicator.answer).then(success, error);

            function success(response) {
                if(typeof indicator.answer.id === 'undefined'){
                    indicator.answer.id = response.data.id;
                }
                if(typeof response.lines !== 'undefined' ){
                    indicator.answer.lines = response.data.lines;
                }
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function saveMonthlyEvidence(data) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: routeService.apiRoute() +'/monthly-evidences/save',
                headers: {'Content-Type': undefined, skipAuthorization: true},
                processData: false,
                contentType: false,
                dataType: "JSON",
                data: data
            }).success(success)
            .error(error);

            function success(response) {
              
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getMonthlyEvidence(data) {
            var deferred = $q.defer();
            var url = '/api/getEvidencesByMonthAndIndicator';
            $http.post(url, data).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function removeMonthlyEvidence(id) {
            var deferred = $q.defer();
            $http.delete('/api/MonthlyEvidence/'+id).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }
            function error(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function remove(id) {

            var deferred = $q.defer();

            $http.delete('/api/answers/'+id).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        

        function downloadMonthRecord(data) {

            var deferred = $q.defer();

            $http.post('/api/downloadMonthRecord', data).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            
            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        
        function updateMonthlyEvidence(data) {

            var deferred = $q.defer();

            $http({
                method: 'POST',
                url: '/api/updateMonthlyEvidence',
                headers: {'Content-Type': undefined, skipAuthorization: true},
                processData: false,
                contentType: false,
                dataType: "JSON",
                data: data
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            
            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function isMonthlyCompleted(line) {
            if (!line) {
                return false;
            }

            var completed = months.every(function(month) {
                // console.log(line[month]);
                return hasValidValue(line[month]);
            });

            return completed;

        }

        function quantityMonthly(line) {
            if (!line) {
                return false;
            }
            var quantity = 0;
            months.forEach(function(month) {
                // console.log(line[month]);
                hasValidValue(line[month]) ? quantity++ : '';
            });

            return quantity;
        }

        function hasValidValue(value) {
            if (typeof value === 'undefined' || value === null || value === '') {
                return false;
            }

            return true;
        }

    }
})();
