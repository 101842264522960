angular.module('main')
    .directive('emissionFugitivas', function() {
        return {
            scope: {
                indicator: '=',
                container: '=',
                id: '=',
                openModalEvidences: '=',
                openModalEvidencesZip: '=',
                month: '='
            },
            restrict: 'E',
            replace: true,
            templateUrl: 'emission-fugitivas.tpl.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller($uibModal,companyCertificationService,companyCertification) {
            var vm = this;

            vm.save = save;
            vm.specificSummaryf = specificSummary;
            vm.addEmissionDirect = addEmissionDirect;
            vm.updateEquipmentFilter = updateEquipmentFilter;
            vm.selectedMonth = vm.month[0];
           vm.showConfirmation = showConfirmation;
            vm.showEquiomentList = showEquiomentList;


            if (typeof vm.indicator.answers === 'undefined') {
                loadAnswers();
                loadEquipmentFilter();
            }else{
                loadAnswers();
                loadEquipmentFilter(); 
            }
            function loadAnswers() {
                companyCertificationService.loadIndicatorAnswersCalculator(vm.id, vm.indicator.id,'direct-emission',1,companyCertification.year)
                .then(success)
                .catch(error);
                function success(response) {
                  vm.indicator.answers = response.data;
                  loadEmissionActivities();
                  emissionSummary();
                }

                function error(error) {
                    console.log(error);
                }  
            }


            function loadEquipmentFilter() {
                companyCertificationService.loadEquipmentFilter(vm.id,'calcu_002',3)
                .then(success)
                .catch(error);
                function success(response) {
                  vm.equipments = response.data;
                }

                function error(error) {
                    console.log(error);
                }  
            }

            function save() {
                data = { 
                   "indicator_id": vm.indicator.id ,
                   "emission_direct_type": 'fugitiva',
                   "emission": 'direct',
                   "company_certification_id": vm.id,
                   "answers": vm.indicator.answers};
                companyCertificationService.saveIndicatorAnswersCalculator( data ,'direct-emission')
                .then(success)
                .catch(error);
                function success(response) {
                   
                    loadAnswers();
                }

                function error(error) {
                    console.log(error);
                }               
            }

            function addEmissionDirect() {
                data = { 
                    "indicator_id": vm.indicator.id ,
                    "emission_direct_type": 'fugitiva',
                    "emission": 'direct',
                    "company_certification_id": vm.id};

                companyCertificationService.addResource( data,'direct-emission' )
                .then(success)
                .catch(error);
                function success(response) {
                    loadAnswers();
                    loadEquipmentFilter(); 
                }    
                function error(error) {
                    console.log(error);
                }    

            }
          
            function specificSummary() {
                vm.specificSummary = [];

                const volatil = [];

                for (var index = 0; index < vm.indicator.answers.length; index++) {
                    if (typeof vm.indicator.answers[index].equipment !== 'undefined') {
                        if (typeof vm.indicator.answers[index].equipment.emission_activity !== 'undefined') {
                            if (typeof vm.emissionActivitiesSelect2 !== 'undefined') {
                                if (vm.indicator.answers[index].equipment.emission_activity.id == vm.emissionActivitiesSelect2.id) {
                                    volatil.push(vm.indicator.answers[index]);
                                } 
                            }
                        }
                    }    
                }

                var equipos = '';
                var total =0;
                var unidades ='';

                var fpcg = 0;

                for (var index = 0; index < volatil.length; index++) {
                    equipos += volatil[index].equipment.name+ ' ,'; 
                    if (typeof vm.indicator.answers[index].equipment.unit_of_measure.name !== 'undefined') {
                        unidades += volatil[index].equipment.unit_of_measure.name+ ' ,'; 
                    }
                    var totalEquipoConsumo =  totalConsumo(volatil[index].month_emission);


                     total += totalEquipoConsumo;
                     fpcg = volatil[index].equipment.emission_activity.emissionFactor.horizon_warming_potential;
                    
               }
               
               vm.specificSummary['equipos'] = equipos ;
               vm.specificSummary['unidades'] = unidades;
               vm.specificSummary['totalConsumo'] = total ;
               vm.specificSummary['potencial'] =  fpcg;
               vm.specificSummary['totalc02e'] = ((fpcg * total).toFixed(3))  /  1000;
              }
            function emissionSummary() {
                vm.emissionSummary = [];

                const volatil = [];
              
                var total =0;
                var unidades ='';
                var totalSummary =0;
                var fpcg = 0;
                for (var index = 0; index < vm.indicator.answers.length; index++) {
                    if (typeof vm.indicator.answers[index].equipment !== 'undefined') {
                        if (typeof vm.indicator.answers[index].equipment.unit_of_measure.name !== 'undefined') {
                            unidades =  vm.indicator.answers[index].equipment.unit_of_measure.name+ ' ,'; 
                            var totalEquipoConsumo =  totalConsumo( vm.indicator.answers[index].month_emission);
                            
                           
                             total += totalEquipoConsumo;
                             fpcg = vm.indicator.answers[index].equipment.emission_activity.emissionFactor.horizon_warming_potential;
                           
                             totalSummary += fpcg * totalEquipoConsumo;
                        }
                     }
                }


               vm.emissionSummary['unidad'] = unidades ;
               vm.emissionSummary['totalConsumo'] = total ;
               vm.emissionSummary['totalSummary'] = (totalSummary.toFixed(3) ) /  1000;

              }

            function totalConsumo(emission) {
                var total = 0;

                total += typeof emission.january.consumo === 'undefined' ? 0 : parseFloat(  emission.january.consumo );
                total += typeof emission.february.consumo === 'undefined' ? 0 :parseFloat( emission.february.consumo);
                total += typeof emission.march.consumo === 'undefined' ? 0 :parseFloat( emission.march.consumo);
                total += typeof emission.april.consumo === 'undefined' ? 0 : parseFloat(emission.april.consumo);
                total += typeof emission.may.consumo === 'undefined' ? 0 :parseFloat( emission.may.consumo);
                total += typeof emission.june.consumo === 'undefined' ? 0 :parseFloat( emission.june.consumo);
                total += typeof emission.july.consumo === 'undefined' ? 0 : parseFloat(emission.july.consumo);
                total += typeof emission.august.consumo === 'undefined' ? 0 :parseFloat( emission.august.consumo);
                total += typeof emission.september.consumo === 'undefined' ? 0 : parseFloat(emission.september.consumo);
                total += typeof emission.october.consumo === 'undefined' ? 0 :parseFloat( emission.october.consumo);
                total += typeof emission.november.consumo === 'undefined' ? 0 : parseFloat(emission.november.consumo);
                total += typeof emission.december.consumo === 'undefined' ? 0 :parseFloat( emission.december.consumo);
                return total;
             }
             function loadEmissionActivities() {
                companyCertificationService.loadEmissionActivities()
                    .then(success)
                    .catch(error);

                function success(response) {
                    vm.emissionActivities = response.data;
                    vm.emissionActivitiesSelect = response.data[0];
                    specificSummary();
                }

                function error(error) {
                    console.log(error);
                }
            }

            function updateEquipmentFilter(oldE,newE,key) {
                if ( newE != null) {
                    for (var index = 0; index <   vm.equipments.length; index++) {
                        if(vm.equipments[index].id == oldE.id){
                         vm.equipments[index].notAnOption = 0;
                        }
                        if(vm.equipments[index].id == newE.id){
                         vm.equipments[index].notAnOption = 1;
                        }
                     }
                }else{
                    vm.indicator.answers[key].equipment =  oldE;
                }
              }

             function showConfirmation(answer, key) {
                $uibModal.open({
                    animation: true,
                    component: 'deleteAnswerEmission',
                    resolve: {
                        emissionAnswer: function () {
                            return answer;
                        },
                        key: function () {
                            return key;
                        },
                        loadAnswers: function () {
                            return loadAnswers;
                        }
                    }
                });
              }

              function showEquiomentList(answers, key) {
                $uibModal.open({
                    animation: true,
                    component: 'listEquipment',
                    resolve: {
                        equipment: function () {
                            return answers;
                        },
                        key: function () {
                            return key;
                        },
                    }
                });
              }
        }
    });