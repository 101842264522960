(function () {
    'use strict';

    angular
            .module('main')
            .service('routeService', routeService);

    function routeService() {

        return {
            apiRoute: apiRoute,
            routeWithout: routeWithout
        };

        function apiRoute() {

            return 'https://panel.susty.org/api';
        }
        function routeWithout() {

            return 'https://panel.susty.org';
        }

      
    }
})();