angular.module('main')
        .directive('summaryIndicator', function () {
            return {
                scope: {
                    indicator: '=',
                    container: '=',
                    onClick: '&',                    
                    industries: '=',
                    companyid: '=',
                },
                restrict: 'E',
                templateUrl: 'summary-indicator.tpl.html',
                controller: controller
            };

            function controller($scope, $sce, $filter, companyCertificationService, $stateParams,countryService,$rootScope) {
                var inputText = $scope.indicator.help;
                $scope.showGraphGroup = false;
                $scope.estadoDiv = estadoDiv;
                $scope.updateGraphic = updateGraphic;
                $scope.model = [];

                $scope.type_selects  = [,'Todos',  'Cliente', 'Proveedor','Sucursal' ];
                $scope.numberOfEmployees = ['Todos','1-20','20-50','50-100','100-500','más de 500'];
                $scope.sectores = [
                    {id: 'Todos', name: 'Todos'},
                    {id: 'publico', name: 'Público'},
                    {id: 'privado', name: 'Privado'},
                ];
                $scope.size = [
                    {id: 'Todos', name: 'Todos'},
                    {id: 'micro', name: 'Micro'},
                    {id: 'small', name: 'Pequeña'},
                    {id: 'medium', name: 'Mediana'},
                    {id: 'big', name: 'Grande'},
                ];
                $scope.criticalities = [
                    {id: 'Todos', name: 'Todos'},
                    {id: '1', name: 'Baja'},
                    {id: '2', name: 'Media'},
                    {id: '3', name: 'Alta'},
                ];
                
              
                if(typeof inputText === 'string' || inputText instanceof String){
                    $scope.indicator.help = $sce.trustAsHtml($scope.indicator.help);  
                }
                $scope.uploadProperties = {
                    type: 'evidence',
                    accept: '*/*',
                    preview: false,
                    multiple: true
                };

                $scope.applyFormula = applyFormula;
                $scope.loadSubAnswers = loadSubAnswers;
                
                function applyFormula(lines, formula, key) {
                    var operations = formula.operations.replace(/,/g, ' ');

                    var values = {};
                    for(var i=0; i<lines.length; i++) {
                        var value = 0;
                        if(typeof key !== 'undefined') {
                            value += parseFloat(lines[i][key]) || 0;
                        } else {
                            value = $filter('sum')(lines[i]);
                        }
                        values[lines[i].indicator] = values[lines[i].indicator] ? (values[lines[i].indicator] + value) : value;
                    }

                    for (var k in values){
                      var re = new RegExp(k,'g');
                      operations =  operations.replace(re, values[k]);
                    }

                    operations =  operations.replace(new RegExp('LENGTH', 'g'), lines.length);

                    var result = 0;

                    try {
                        result = eval(operations);
                    } catch (e) {
                        console.log(e);
                    }

                    if(!isFinite(result)) {
                        result = 0;
                    }

                    result = isNaN(result) ? 0 : result;
                    result = result % 1 !== 0 ? result.toFixed(2) : result;
                    return result + (formula.symbol ? formula.symbol : '');
                }

                function loadSubAnswers() {
                    companyCertificationService.loadIndicatorSummary($stateParams.id, $scope.indicator.id).then(success, error);
   
                    function success(response) {
                        $scope.indicator.answer = { subAnswers: response };
                        var ids = $scope.indicator.answer.subAnswers.map(function(item) { return item.office_id;});

                        //
                        countryService.loadCountryAndCity($scope.companyid, ids).then(success, error);
                           function success(response) {
                            $scope.countries = response.countries;
                            $scope.cities = response.cities; 
                            if (!Array.isArray($scope.countries)) {
                                $scope.countries = Object.values(response.countries);
                            }
                            if (!Array.isArray($scope.cities)) {
                                $scope.countries = Object.values(response.cities);
                            }              
                 
                            if(typeof  $scope.cities === 'undefined'){
                                $scope.cities = []; 
                            }
                            if(typeof  $scope.countries === 'undefined'){
                                $scope.countries = []; 
                            }
                                 $scope.cities.unshift({ id: 'Todos', ciudad: 'Todos' });
                                 $scope.countries.unshift({ id: 'Todos', pais: 'Todos' });
                            }
        
                            function error(error) {
                            console.error(error);
                            }
                
                        minAndMax($scope.indicator.answer.subAnswers)
                    }

                    function error(error) {
                      console.error(error);
                    }
                }

                function minAndMax(lines) {
                  var values = [];
                  for(var i=0; i<lines.length; i++) {
                      values.push({
                          name: lines[i].office,
                          value: $filter('sum')(lines[i])
                      });
                  }

                  $scope.max = values.reduce(function(prev, current) {
                    return (prev.value > current.value) ? prev : current
                  });

                  $scope.min = values.reduce(function(prev, current) {
                    return (prev.value < current.value) ? prev : current
                  });
                }
                function estadoDiv() {
                    $scope.indicator.showGraphGroup = false;
                    document.getElementById('chart-'+$scope.indicator.id).style.display = 'none';
                }

                function updateGraphic(indicator_id,indicator) {
                  
                    $rootScope.$broadcast('modelChanged', $scope.model);
            

                }
            }
        });