angular.module('main')
        .directive('listEquipment', function () {
            return {
                scope: {
                    resolve: '<',
                    close: '&',
                    dismiss: '&'
                },
                restrict: 'E',
                templateUrl: 'list-equipment.tpl.html',
                controller: controller
            };

            function controller($scope) {
                init();

                function init() {
                    $scope.model = {};
                    $scope.equipment = $scope.resolve.equipment;
                    $scope.key = $scope.resolve.key;
                    
                }
               
            
            }
        });