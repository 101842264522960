(function () {
    'use strict';

    angular
            .module('main')
            .service('countryService', countryService);

    function countryService($q, $http,routeService) {

        return {
            findAll: findAll,
            findAllP: findAllP,
            findAllC: findAllC,
            loadCountryAndCity: loadCountryAndCity

        };

        function findAll() {
            var deferred = $q.defer();

            $http.get('/api/countries', {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function findAllP() {
            var deferred = $q.defer();

            $http.get('/api/countriesP', {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
               
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        function findAllC(code) {
            var deferred = $q.defer();

            $http.get('/api/countriesC/'+code, {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
               
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function loadCountryAndCity(company_id,ids) {
            var deferred = $q.defer();
            
            $http.get(routeService.apiRoute()+'/countries/countriesAndCity/'+company_id, {
                headers: {skipAuthorization: true},
                params: {ids: JSON.stringify(ids)}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
    }
})();