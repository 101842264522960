angular.module('main')
    .directive('emissionElectrical', function() {
        return {
            scope: {
                indicator: '=',
                id: '=',
                significativa: '=',
                openModalEvidences: '=',
                openModalEvidencesZip: '=',
                month: '='
            },
            restrict: 'E',
            replace: true,
            templateUrl: 'emission-electrical.tpl.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller($uibModal,companyCertificationService,companyCertification) {
            var vm = this;

            vm.save = save;
            vm.addEmissionDirect = addEmissionDirect;
            vm.selectedMonth = vm.month[0];
            vm.updateEquipmentFilter = updateEquipmentFilter;
           vm.showConfirmation = showConfirmation;
            vm.showEquiomentList = showEquiomentList;
              
            if (typeof vm.indicator.answers === 'undefined') {
                loadAnswers();
                
                loadEquipmentFilter();
            }else{
                loadAnswers();
                loadEquipmentFilter(); 
            }
            
            function loadAnswers() {
                companyCertificationService.loadIndicatorAnswersCalculator(vm.id, vm.indicator.id,'direct-emission',1,companyCertification.year)
                .then(success)
                .catch(error);
                function success(response) {
                  vm.indicator.answers = response.data;
                  vm.factorEmission = response.factorEmission;
                  loadEmissionActivities();
                  emissionSummary();
                }

                function error(error) {
                    console.log(error);
                }  
            }


            function loadEquipmentFilter() {
                companyCertificationService.loadMeasurersFilter('indirect',1,vm.id,vm.significativa.yearlyIndicators[1].id)
                .then(success)
                .catch(error);
                function success(response) {
                  vm.equipments = response.data[0].measurers;
                }
                function error(error) {
                    console.log(error);
                }  
            }

            function save() {
                var option = 'electricidad';

                data = { 
                   "indicator_id": vm.indicator.id ,
                   "emission_direct_type": option,
                   "emission": 'indirect',
                   "company_certification_id": vm.id,
                   "answers": vm.indicator.answers};
                companyCertificationService.saveIndicatorAnswersCalculator( data ,'direct-emission')
                .then(success)
                .catch(error);
                function success(response) {
                    console.log(response);
                    loadAnswers();
                }

                function error(error) {
                    console.log(error);
                }               
            }

            function addEmissionDirect() {

                var option = 'electricidad';
                data = { 
                    "indicator_id": vm.indicator.id ,
                    "emission_direct_type": option,
                    "emission": 'indirect',
                    "company_certification_id": vm.id};

                companyCertificationService.addResource( data,'direct-emission' )
                .then(success)
                .catch(error);
                function success(response) {
                    loadAnswers();
                    loadEquipmentFilter(); 
                }    
                function error(error) {
                    console.log(error);
                } 
            }

            function loadEmissionActivities() {
                companyCertificationService.loadEmissionActivities()
                .then(success)
                .catch(error);
                function success(response) {
                  vm.emissionActivities = response.data;
                  vm.emissionActivitiesSelect = response.data[0];
                }
                function error(error) {
                    console.log(error);
                }  
            }

            function emissionSummary() {
                vm.emissionSummary = [];

                const volatil = [];
                vm.january =0;
                vm.february =0; 
                vm.march = 0;
                vm.april =0;
                vm.may =0; 
                vm.june =0;
                vm.july =0; 
                vm.august =0;
                vm.september =0; 
                vm.october =0;
                vm.november =0; 
                vm.december =0; 
               
                var c02Formula = vm.factorEmission.co2;

                for (var index = 0; index < vm.indicator.answers.length; index++) {
                    if (typeof vm.indicator.answers[index].measurer !== 'undefined') {       
                             totalConsumo( vm.indicator.answers[index].month_emission);
                     }
                }
                var total = vm.january +
                vm.february + 
                vm.march + vm.april +
                vm.may + 
                vm.june +
                vm.july + 
                vm.august +
                vm.september + 
                vm.october +
                vm.november + 
                vm.december  ;

               vm.emissionSummary['totalConsumo'] = total;
               vm.emissionSummary['january'] = (( vm.january  * c02Formula) / 1000).toFixed(3);
               vm.emissionSummary['february'] = (( vm.february  * c02Formula) / 1000).toFixed(3);
               vm.emissionSummary['march'] = (( vm.march  * c02Formula) / 1000).toFixed(3);
               vm.emissionSummary['april'] = (( vm.april  * c02Formula) / 1000).toFixed(3);
               vm.emissionSummary['may'] = (( vm.may  * c02Formula) / 1000).toFixed(3);
               vm.emissionSummary['june'] = (( vm.june  * c02Formula) / 1000).toFixed(3);

               vm.emissionSummary['july'] = (( vm.july  * c02Formula) / 1000).toFixed(3);
               vm.emissionSummary['august'] = (( vm.august  * c02Formula) / 1000).toFixed(3);
               vm.emissionSummary['september'] = (( vm.september  * c02Formula) / 1000).toFixed(3);
               vm.emissionSummary['october'] = (( vm.october  * c02Formula) / 1000).toFixed(3);
               vm.emissionSummary['november'] = (( vm.november  * c02Formula) / 1000).toFixed(3);
               vm.emissionSummary['december'] = (( vm.december  * c02Formula) / 1000).toFixed(3);

               vm.emissionSummary['totalSummary'] = (( (total * c02Formula) / 1000).toFixed(3));

              }

             function totalConsumo(emission) {
                vm.january += typeof emission.january.consumo === 'undefined' ? 0 : parseInt(  emission.january.consumo );
                vm.february += typeof emission.february.consumo === 'undefined' ? 0 :parseInt( emission.february.consumo);
                vm.march += typeof emission.march.consumo === 'undefined' ? 0 :parseInt( emission.march.consumo);
                vm.april += typeof emission.april.consumo === 'undefined' ? 0 : parseInt(emission.april.consumo);
                vm.may += typeof emission.may.consumo === 'undefined' ? 0 :parseInt( emission.may.consumo);
                vm.june += typeof emission.june.consumo === 'undefined' ? 0 :parseInt( emission.june.consumo);
                vm.july += typeof emission.july.consumo === 'undefined' ? 0 : parseInt(emission.july.consumo);
                vm.august += typeof emission.august.consumo === 'undefined' ? 0 :parseInt( emission.august.consumo);
                vm.september += typeof emission.september.consumo === 'undefined' ? 0 : parseInt(emission.september.consumo);
                vm.october += typeof emission.october.consumo === 'undefined' ? 0 :parseInt( emission.october.consumo);
                vm.november += typeof emission.november.consumo === 'undefined' ? 0 : parseInt(emission.november.consumo);
                vm.december += typeof emission.december.consumo === 'undefined' ? 0 :parseInt( emission.december.consumo);
              }
              function updateEquipmentFilter(oldE,newE,key) {
                if ( newE != null) {
                    for (var index = 0; index <   vm.equipments.length; index++) {
                        if(vm.equipments[index].id == oldE.id){
                         vm.equipments[index].notAnOption = 0;
                        }
                        if(vm.equipments[index].id == newE.id){
                         vm.equipments[index].notAnOption = 1;
                        }
                     }
                }else{
                    vm.indicator.answers[key].measurer =  oldE;
                }
              }

             function showConfirmation(answer, key) {
                $uibModal.open({
                    animation: true,
                    component: 'deleteAnswerEmission',
                    resolve: {
                        emissionAnswer: function () {
                            return answer;
                        },
                        key: function () {
                            return key;
                        },
                        loadAnswers: function () {
                            return loadAnswers;
                        }
                    }
                });
              }

              function showEquiomentList(answers, key) {
                $uibModal.open({
                    animation: true,
                    component: 'listEquipment',
                    resolve: {
                        equipment: function () {
                            return answers;
                        },
                        key: function () {
                            return key;
                        },
                    }
                });
              }

        }
    });