(function () {
    'use strict';

    angular
            .module('main')
            .service('fileService', fileService);

    function fileService($q, $http,routeService) {

        return {
            save: save,
            remove: remove,
        };

        function save(file, type,urls) {
            var deferred = $q.defer();
            var formData = new FormData();
            formData.append('file', file);
            formData.append('type', type);
            formData.append('urls',  JSON.stringify(urls));
            file.progress = 0;
            $http({
                method: 'POST',
                url: routeService.apiRoute() +'/files/save',
                headers: {'Content-Type': undefined, skipAuthorization: true},
                data: formData,
                uploadEventHandlers: {
                    progress: progress
                }
            })
                    .success(success)
                    .error(error)
                    ;

            function success(response) {
                deferred.resolve(response);
            }

            function error(error) {
                deferred.reject(error);
            }

            function progress(progress) {
                file.progress = progress.loaded / progress.total * 100;
            }

            return deferred.promise;
        }



        function remove(id) {
          var deferred = $q.defer();
          $http.delete(routeService.apiRoute() + '/files/delete/'+id)
            .success(success)
            .error(error)
          ;

          function success(response) {
         
            deferred.resolve(response);
          }

          function error(error) {
            deferred.reject(error);
          }

          return deferred.promise;
        }
    }
})();