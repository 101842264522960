angular.module('main')
    .directive('calculatorWindow', function() {
        return {
            scope: {
                id: '=',
                title: '=',
                resumeEquipment: '=',
                type: '=',
                subType: '=',
                gotoAnchor: '=',
                onClick: '&'
            },
            restrict: 'E',
            replace: true,
            templateUrl: 'calculator-window.tpl.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller($uibModal,companyCertificationService) {
            var vm = this;
            vm.filteredTodos = [];
            vm.currentPage = 1;
            vm.itemsPerPage = 4;
            vm.setPagingData = setPagingData;            
            vm.showConfirmation = showConfirmation;
            loadResumeEquipment();
           
            function loadResumeEquipment() {
                companyCertificationService.resumeEquipment(['calcu_002','calcu_012','calcu_013'], vm.id).then(success, error);
                function success(response) {
                    vm.resumeEquipment = response.data;
                    loadFilterResume();
                    setPagingData();
                }

                function error(error) {
                    console.log(error);
                }
            }

            function loadFilterResume() { 
              vm.resumeFilterEquipment = vm.resumeEquipment[vm.type];
              if (typeof vm.subType !== 'undefined' ) {
                var subEquipment = [];
                for (var index = 0; index < vm.resumeFilterEquipment.length; index++) {
                  if (vm.resumeFilterEquipment[index].emission_type == vm.subType) {
                    if (vm.resumeFilterEquipment[index].answer != null) {
                        subEquipment.push(vm.resumeFilterEquipment[index]);
                    }
                    
                  }
                    
                }
               vm.resumeFilterEquipment = subEquipment; 
              }

              vm.totalItem = vm.resumeFilterEquipment.length;
            }


            function setPagingData() {
                var pagedData = vm.resumeFilterEquipment.slice((vm.currentPage - 1) * vm.itemsPerPage, vm.currentPage * vm.itemsPerPage);
                vm.filteredTodos = pagedData;
            }

            function showConfirmation(equipment, key) {

                key = 'equipment';
                if (typeof vm.subType !== 'undefined' ) {
                    if (equipment.answer != null) {
                        key = 'answer';
                        equipment.id = equipment.answer.id;
                       }
                }
              
              
                $uibModal.open({
                    animation: true,
                    component: 'deleteEquipment',
                    resolve: {
                        emissionAnswer: function () {
                            return equipment;
                        },
                        key: function () {
                            return key;
                        },
                        loadAnswers: function () {
                            return loadResumeEquipment;
                        }
                    }
                });
              }
            

              
        }
    });