(function () {
    'use strict';

    angular
            .module('main')
            .controller('NewCompanyController', NewCompanyController);

    function NewCompanyController(howDidYouFindUsOptionService, companyService, industryService, countryService) {
        var vm = this;
        vm.step = 0;
        vm.field = 0;
        vm.model = {howDidYouFindUsOptions: []};
        vm.save = save;
        vm.uploadProperties = {
            type: 'company-logo',
            accept: '*/*',
            preview: true,
            multiple: false
        };
        loadCountries();
        loadIndustries();
        loadHowDidYouFindUsOptions();

        function save(form) {
            if (vm.field < 7) {
                vm.field++;
                return;
            }

            if (!form.$valid) {
                form.$setSubmitted();
                vm.field = getIndexOfFirstFieldWithError(form);
                return;
            }

            companyService.save(vm.model)
                    .then(success)
                    .catch(error);

            function success() {
                vm.step = 1;
            }

            function error(error) {
                console.log(error);
                vm.step = 2;
            }
        }

        function getIndexOfFirstFieldWithError(form) {
            var index = -1;
            for (var item in form) {
                if (item[0] === '$') {
                    continue;
                }
                index++;

                if (!form[item].$valid) {
                    return index;
                }
            }
            return index;
        }

        function loadCountries() {
            countryService.findAll()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.countries = response;
            }

            function error(error) {
                console.log(error);
            }
        }

        function loadIndustries() {
            industryService.findAll()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.industries = response;
            }

            function error(error) {
                console.log(error);
            }
        }

        function loadHowDidYouFindUsOptions() {
            howDidYouFindUsOptionService.findAll()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.howDidYouFindUsOptions = response;
            }

            function error(error) {
                console.log(error);
            }
        }
    }

})();