angular.module('main')
        .directive('officeForm', function () {
            return {
                scope: {
                    model: '=',
                    countries: '=',
                    currencies: '=',
                    paises: '=',
                    ciudades: '='
                },
                restrict: 'E',
                transclude: true,
                replace: true,
                templateUrl: 'office-form.tpl.html',
                controller: controller
            };
            
            function controller($scope,industryService,countryService,user, userService) {

                $scope.type_selects  = [  'Cliente', 'Proveedor','Sucursal' ];
                $scope.numberOfEmployees = ['1-20','20-50','50-100','100-500','más de 500'];
                $scope.loadCiudades = loadCiudades;    
                $scope.hasPermissions = hasPermissions;
      
               
                loadIndustries();
                function loadIndustries() {
                    industryService.findAll()
                            .then(success)
                            .catch(error);
        
                    function success(response) {
                        $scope.industries = response;
                    }
        
                    function error(error) {
                        console.log(error);
                    }
                }

                function loadCiudades(code) {
                 
                    countryService.findAllC(code)
                            .then(success)
                            .catch(error);
        
                    function success(response) {      
                        $scope.ciudades = response
                    }
        
                    function error(error) {
                        console.log(error);
                    }
                }

                function hasPermissions(roles) {
                    return userService.hasPermissions(user, roles);
                }
    
            }
        });