angular.module('main')
    .directive('calculator', function() {
        return {
            scope: {
                id: '=',
                parentContainer: '=',
            },
            restrict: 'E',
            templateUrl: 'calculator.tpl.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller( $state,$anchorScroll, $location,$uibModal, companyCertificationService,answerService) {
            var vm = this;
            vm.subMenu = false;
            vm.container = [];
            vm.indicator = [];
            vm.init = 0;
            vm.type = '';
            vm.loadIndicators = loadIndicators;
            vm.optionWindow = optionWindow;
            vm.optionWindowTotals = optionWindowTotals;
            vm.openModalEvidences = openModalEvidences;
            vm.openModalEvidencesZip = openModalEvidencesZip;
            vm.resumeEquipment =[];
            $anchorScroll, $location,
            vm.gotoAnchor = gotoAnchor;
            vm.openModalHelper = openModalHelper;
            vm.open_modal = open_modal;

            vm.close = close;
            function close() {
              $state.reload();
            }

              

            function gotoAnchor(value) {
                var newHash = 'equipment-' + value;
                if ($location.hash() !== newHash) {
                    // set the $location.hash to `newHash` and
                    // $anchorScroll will automatically scroll to it
                    $location.hash('equipment-' + value);
                } else {
                    // call $anchorScroll() explicitly,
                    // since $location.hash hasn't changed
                    $anchorScroll();
                }
            }
            vm.month = [
                {id: 'january', name: 'Enero', month: 1},
                {id: 'february', name: 'Febrero', month: 2},
                {id: 'march', name: 'Marzo', month: 3},
                {id: 'april', name: 'Abril', month: 4},
                {id: 'may', name: 'Mayo', month: 5},
                {id: 'june', name: 'Junio', month: 6},
                {id: 'july', name: 'Julio', month: 7},
                {id: 'august', name: 'Agosto', month: 8},
                {id: 'september', name: 'Septiembre', month: 9},
                {id: 'october', name: 'Octubre', month: 10},
                {id: 'november', name: 'Noviembre', month: 11},
                {id: 'december', name: 'Diciembre', month: 12}
              ];


            function loadIndicators(container) {

                container.totalMonthlyIndicators = 0;

                container.monthlyIndicators = [];
                container.yearlyIndicators = [];
                companyCertificationService.loadIndicators(vm.id, container.id).then(success, error);
               
                function success(response) {
                    filterIndicators(container, response);
                    if (vm.init == 0) {
                        optionWindow(vm.parentContainer.subContainers[0].name, vm.parentContainer.subContainers[0], vm.parentContainer.subContainers[0].yearlyIndicators[0]);
                        vm.init = 1;
                    }
                }

                function error(error) {
                    console.log(error);
                }

                $.datetimepicker.setLocale('es');
                $("#txt-fecha-modal-agregar-evidencias").datetimepicker({timepicker:false, format:'d/m/Y', hour : '12', minute :'00'});
           
            }

            function filterIndicators(container, indicators) {
                var expr = new RegExp(/MONTHLY|SUMMARY/);

                container.monthlyIndicators = indicators.filter(monthlyIndicators);

                container.requiredMonthlyIndicators = 0;
                container.completedMonthlyIndicators = 0;
                if (container.monthlyIndicators.length > 0) {
                    vm.parentContainer.hasMonthlyIndicators = true;
                }

                container.yearlyIndicators = indicators.filter(yearlyIndicators);
                container.requiredYearlyIndicators = 0;
                container.completedYearlyIndicators = 0;
                if (container.yearlyIndicators.length > 0) {
                    vm.parentContainer.hasYearlyIndicators = true;
                }

                function monthlyIndicators(element) {
                    if (expr.test(element.type)) {
                        return element;
                    }
                }

                function yearlyIndicators(element) {
                    if (!expr.test(element.type)) {
                        return element;
                    }
                }
            }


            function optionWindowTotals(option, name) {
                vm.optionParent = option;
                vm.nameContainer = name;
                vm.nameSubContainer = '';
            }

            function optionWindow(option, container, indicator, type) {

                vm.optionParent = option;
                vm.nameContainer = container.name;
                if (vm.optionParent == vm.nameContainer) {
                    if (typeof indicator.name === 'undefined') {
                        vm.optionParent = option + 'C';
                    }
                    vm.nameSubContainer = '';
                } else {
                    vm.nameSubContainer = ' / ' + indicator.name;
                }
                if ("Emisiones directas" == container.name) {
                    if ('Residuos' == indicator.name) {
                        vm.optionParent = vm.optionParent + 'D';
                    }
                }
                if ("Emisiones significativas" == container.name) {
                    if ('Residuos' == indicator.name) {
                        vm.optionParent = vm.optionParent + 'S';
                    }

                }
                
                vm.container = container;
                vm.indicator = indicator;
                vm.stateInfo = true;
                if (vm.indicator.length == 0) {
                    vm.indicator =  container.monthlyIndicators[0];
                }

                vm.type = type;
            }

            function openModalEvidences(id, title, month,idEmission) {
                console.log(id, title, month,idEmission);
                if (id != null && title != null && month != null) {
                    $("#modal-agregar-evidencias-titulo").html(title);
                    $("#slt-mes-modal-agregar-evidencias").val(month);
                    $("#txt-answer-id-modal-agregar-evidencias").val(id);
                    $("#txt-emission-answer-id-modal-agregar-evidencias").val(idEmission);
                }

                $("#amount-div").toggle(false);
                $("#consumo-div").toggle(false);
                $("#description-div").toggle(true);
                $("#monto-evidencias").text("");
                $("#consumo-evidencias").text("");
                $("#fecha-evidencias").text("");
                $("#imagen-evidencias").text("");

                $("#txt-monto-modal-agregar-evidencias").val('');
                $("#txt-consumo-modal-agregar-evidencias").val(0);
                $("#txt-fecha-modal-agregar-evidencias").val('');
                $("#txt-description-modal-agregar-evidencias").val('');
                $("#file-imagen-modal-agregar-evidencias").val('');
                $("#btn-seleccionar-archivo-evidencias>span").text("Seleccionar archivo");
                $("#btn-seleccionar-archivo-evidencias>img").attr("src", "../img/content/adjuntar.png");

                $("#lbl-evidencias-adjuntas").hide();
                $("#modal-agregar-evidencias").modal();
                data = {
                    "indicatorAnswerId": $("#txt-answer-id-modal-agregar-evidencias").val(),
                    "month": $("#slt-mes-modal-agregar-evidencias").val(),
                    "emissionAnswerId": $("#txt-emission-answer-id-modal-agregar-evidencias").val()
                }
                $("#evidencias").html("<div class='modal-loader'></div>");

                answerService.getMonthlyEvidence(data)
                    .then(success)
                    .catch(error);

                function success(response) {
                  
                    if (response.length <= 0) {
                        $("#evidencias").html("");
                    } else {
                        var html = '';
                        var index = 0;
                        $("#count-evidencias").text(response.length);
                        $("#lbl-evidencias-adjuntas").show();
                        while (index < response.length) {
                            var evidenceDiv = '<div id="evidence-' + response[index].id + '" class="item-evidencia">';
                            if (response[index].path) {
                                evidenceDiv = '<a href="' + response[index].path + '" target="_blank"><div class="div-image"  style="background-image: url(' + response[index].path + ')"></div></a>';
                            } else {
                                evidenceDiv = '<div class="no-evidence-div"><img src="../img/svg/image-not-found.svg" alt="Sin evidencia"><span>Sin evidencia</span></div>';
                            }
                            html += '<div id="evidence-' + response[index].id + '" class="item-evidencia">' + evidenceDiv +
                                '<div class="dropdown">' +
                                '<button class="btn btn-evidence" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
                                new Date(response[index].fecha).toLocaleDateString('es-CR', { day: '2-digit', month: '2-digit', year: 'numeric' }) +
                                '<span>•••</span>' +
                                '</button>' +
                                '<ul class="dropdown-menu">' +
                                '<li><a class="dropdown-item evidence-item" evidence-id="' + response[index].id +  '" descripcion="' + response[index].description + '" monto="' + response[index].amount + '" consumo="' + response[index].consumption + '" fecha="' + response[index].fecha + '" onclick="$(this).attr(\'edit\',1); $(\'#editEvidence\').click()">' +
                                '<div>Editar Evidencia <img src="../img/content/editar.png"></div>' +
                                '</a>' +
                                '<input type="file" name="" id="" style="display:none">' +
                                '</li>' +
                                '<li><a class="dropdown-item" data-toggle="modal" data-target="#modal-eliminar-evidencia" onclick="$(\'#btn-remove-evidence\').attr(\'evidence-id\', ' + response[index].id + ')">' +
                                '<div>Eliminar Evidencia <img src="../img/content/basurero.png"></div>' +
                                '</a>' +
                                '</li>' +
                                '</ul>' +
                                '</div>' +
                                '</div>'
                            index++;
                        }

                        $("#evidencias").html(html);
                    }
                }

                function error(error) {
                    console.log(error);
                }
            }
            

            function openModalEvidencesZip(id, title, month,emissionAnswerId) {
                $("#modal-descargar-evidencias-titulo").html(title);
                $("#btn-evidences-zip").attr("answer-id", id);
                $("#btn-evidences-zip").attr("month", month);
                $("#btn-evidences-zip").attr("emission-id", emissionAnswerId);

                $("#modal-descargar-evidencias").modal();
            }
            function openModalHelper(indicator) {

                var modal =  $uibModal.open({
                      animation: true,
                      component: 'helper',
                      windowClass: 'helper-modal',
                      size: 'lg',
                      resolve: {
                       indicator : indicator
                      }
                  });
              }


              function open_modal(valueIndicator) {
               
               
                 const indicators = vm.container.yearlyIndicators;
                 if ( vm.container.monthlyIndicators.length > 0) {
                    indicators.push(vm.container.monthlyIndicators[0]);
                }
                var indicator = indicators[0];
                if (valueIndicator) {
                     indicator = valueIndicator;
                }
             
              var modal =  $uibModal.open({
                    animation: true,
                    component: 'helper',
                    windowClass: 'helper-modal',
                    size: 'lg',
                    resolve: {
                        indicator: function() {
                            return indicator;
                        },
                        indicators: function() {
                            return indicators;
                        }
                        
                    }
                });
            }


        }

    });