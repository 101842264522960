angular.module('main')
        .directive('uploadFile', function () {
            return {
                scope: {
                    properties: '=',
                    model: '='
                },
                restrict: 'EA',
                transclude: true,
                replace: true,
                templateUrl: 'upload-file.tpl.html',
                controller: controller,
                controllerAs: 'vm',
                bindToController: true
            };
            
       
            function controller(fileService) {
                var vm = this;
                var maximo_bytes  = 1000000000;
                vm.id = new Date().getTime();
                vm.idL = new Date().getTime();
                vm.files = [];
                vm.change = change;
                vm.remove = remove;
                vm.setNone = setNone;
                vm.name = '';
                if(vm.properties.multiple) {
                    vm.files = angular.copy(vm.model);
                }
                if(vm.model && vm.model.url) {
                    var file = angular.copy(vm.model);
                    file.src = file.url;
                    vm.files.push(file);
                }
                function setNone() {
                    document.getElementById("limit"+vm.idL).style.display = "none";        
                }
                function change(files) {
                   
                    for (var i = 0; i < files.length; i++) {
                        var file = files[i];
                        file.progress = 0;

                        vm.name = file.name;
                        if (file.size <= maximo_bytes ) {
                                if (!vm.properties.multiple) {
                                    vm.files = [];
                                }

                                if (vm.properties.preview) {
                                    createPreview(file);
                                }

                                vm.files.push(file);
                                saveFile(file);
                        }else{
                           document.getElementById("limit"+vm.idL).style.display = "block";
                           document.getElementById("textAdvertencia").innerHTML = "El archivo con el nombre de "+file.name+", supera el tamaño máximo permitido de 1 GB.";                  
                        }
                    }
                }

                function getUrls(file) {
                    if (file.name.split(".").pop() == 'pdf') {
                        fileService.urls(file).then(success)
                    .catch(error);
                    function success(response) {
                       guard(file,response);
                    }

                    function error(error) {
                        console.log(error);
                    }
                    }
                }

                function saveFile(file) {
                
                    guard(file,0);

                }

                function guard(file,urls) {
                    fileService.save(file, vm.properties.type,urls)
                    .then(success)
                    .catch(error);

                  function success(response) {
                var index = vm.files.indexOf(file);
                var property;
                for (property in response) {
                }
           
                vm.files[index].id = response[property].id;
                vm.files[index].url = response[property].url;
                for (var property in response) {
                    if (vm.properties.multiple) {
                   
                        vm.model.push({id:  response[property].id});
                    } else {
                      
                        vm.model = {id:  response[property].id};
                    }
                  }
                }
                function error(error) {
                    console.log(error);
                }
                }

                function createPreview(file) {
                    var typesAllowed = ['image/png', 'image/jpeg'];

                    if (typesAllowed.indexOf(file.type) < 0) {
                        return;
                    }

                    var reader = new FileReader();
                    reader.onload = function (e) {
                        file.src = e.target.result;
                    };

                    reader.readAsDataURL(file);
                }

                function remove(id) {
                    fileService.remove(id)
                    .then(success);

                    function success() {
                        for(var i=0; i< vm.files.length; i++) {
                            if(vm.files[i].id === id) {
                                vm.files.splice(i, 1);
                                break;
                            }
                        }

                        if (vm.properties.multiple) {
                            for(var i=0; i< vm.model.length; i++) {
                                if(vm.model[i].id === id) {
                                    vm.model.splice(i, 1);
                                    break;
                                }
                            }
                        } else {
                            vm.model = null;
                        }
                    }
                }
            }
        });

