(function () {
    'use strict';

    angular
            .module('main')
            .service('helperService', helperService);

    function helperService($q, $http,routeService) {

        return {
           
            resourcesAll: resourcesAll,
            feedbackAll: feedbackAll,
            savefeedback: savefeedback,
            saveImprovement: saveImprovement
        };

        
        function resourcesAll(indicator_id) {
            var deferred = $q.defer();
            $http.get(routeService.apiRoute()+'/resources/resourcesIndicator/'+indicator_id, {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }


        function feedbackAll(email) {
            var deferred = $q.defer();
            $http.get(routeService.apiRoute()+'/feedbacks/details/'+email, {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }


      
        function savefeedback(email,feedback_helper_id,star_count) {
            var deferred = $q.defer();
            var data = {'email' : email, 'feedback_helper_id' : feedback_helper_id, 'star_count': star_count};
           
            $http.post(routeService.apiRoute()+'/feedbacks/store',data).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }
            function error(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function saveImprovement(body,email) {
            var deferred = $q.defer();
            var data = {'body' : body, 'email' : email};
           
            $http.post(routeService.apiRoute()+'/improvements/store',data).then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }
            function error(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

      
    }
})();