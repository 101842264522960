var app = angular.module('main');

    /**
     * AngularJS default filter with the following expression:
     * "person in people | filter: {name: $select.search, age: $select.search}"
     * performs an AND between 'name: $select.search' and 'age: $select.search'.
     */
    app.filter('propsFilter', function() {
        return function(items, props) {
            var out = [];

            if (angular.isArray(items)) {
                var keys = Object.keys(props);

                items.forEach(function(item) {
                    var itemMatches = false;

                    for (var i = 0; i < keys.length; i++) {
                        var prop = keys[i];
                        var text = props[prop].toLowerCase();
                        if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                            itemMatches = true;
                            break;
                        }
                    }

                    if (itemMatches) {
                        out.push(item);
                    }
                });
            } else {
                // Let the output be the input untouched
                out = items;
            }

            return out;
        };
    });

    app.directive('certificationPermissions', function () {
        return {
            scope: {
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            restrict: 'E',
            templateUrl: 'certification-permissions.tpl.html',
            controller: controller,
            controllerAs: 'vm'

        };

        function controller($scope, companyService, company, containerService, indicatorService) {
            var vm = this;
            vm.save = save;
            vm.loadOffices = loadOffices;
            vm.step = 1;
            vm.maxSteps = company.availableCertifications.length + 1;
            vm.valid = false;
            vm.pantallaPermisos = pantallaPermisos;
            vm.back = back;
            vm.lastContainer = {};
            vm.availableCertifications = company.availableCertifications;
            // Save structure
            vm.selected = {};
            vm.saveData = indicatorService.getSelectedIndicators();
            vm.displaySelected = [];


            function loadOffices() {
                if(!vm.offices)
                {
                    companyService.findOffices().then(success, error);

                    function success(response) {
                        vm.offices = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }
            }

            loadOffices();


            function updateSelectedList() {
                // Agregar los elementos que pertenecen a la seleccion
                const currentCertificationId = vm.currentCertification.id;
                const officeId = vm.office.id;
                const indicatorsSelected = vm.saveData.filter(function (element) {
                    return element.certification == currentCertificationId
                        && element.office == officeId
                });

                var containers = [];
                indicatorsSelected.forEach(function (indicator) {
                    var container = containers.find(function (container) {
                        return container.id == indicator.container;
                    });

                    if (container == null) {
                        container = {id: indicator.container, name: indicator.containerName, indicators: []};
                        containers.push(container);
                    }

                    container.indicators.push(indicator);
                });
                vm.displaySelected = containers;
               
            }

            vm.validateStep1 = function (item, model) {

                vm.valid = typeof vm.office != "undefined" && vm.office != null
                                    && typeof vm.currentCertification != "undefined"
                                    && vm.currentCertification != null;
                if(vm.valid && vm.saveData.length > 0)
                {
                    updateSelectedList();
                }
            }

            vm.changeSubContainer = function(item, model)
            {
                vm.storeSelection();
                vm.lastContainer = vm.container;
                vm.indicators = [];
                indicatorService.searchByCertificationAndContainer(vm.currentCertification.id,
                    model.id, company.id).then(
                        function(data){vm.indicators = data;},
                        function(error){console.log(error);}
                );
                // Restore Selection
                vm.restoreSelection();

            }

            vm.changeAll = function(){
                vm.indicators.forEach(function (indicator) {
                    vm.selected[indicator.code] = typeof vm.all == "undefined" ? false : vm.all;
                });
            };


            vm.restoreSelection = function(){
                if(vm.currentCertification){
                    const officeId = vm.office.id;
                    const currentCertificationId = vm.currentCertification.id;
                    const containerId = vm.container.id;

                    var cleanSelection = vm.saveData.filter(function(element){
                        return element.certification == currentCertificationId
                            && element.office == officeId
                            && element.container == containerId
                    });

                    vm.selected = {};
                    cleanSelection.forEach(function(element){
                        vm.selected[element.indicatorCode] = true;
                    });
                }
            }
            /**
             * Save current selection in data structure
             */
            vm.storeSelection = function() {

                if (vm.lastContainer && vm.selected != {} && vm.indicators && vm.indicators.length > 0) {
                    const officeId = vm.office.id;
                    const currentCertificationId = vm.currentCertification.id;
                    const containerId = vm.lastContainer.id;
                    const containerName = vm.lastContainer.name;
                    // Remove selection to create a new one
                    var cleanSelection = vm.saveData.filter(function(element){
                            return element.certification != currentCertificationId
                            || element.office != officeId
                            || element.container != containerId
                        }
                    );

                    for(var property in vm.selected) {
                        if(vm.selected[property])
                        {
                            var indictorInfo = vm.indicators.find(function(item){
                                return item.code == property;
                            });

                            cleanSelection.push({
                                certification: currentCertificationId,
                                office: officeId,
                                container: containerId,
                                containerName: containerName,
                                indicatorCode: property,
                                indicator: indictorInfo.id,
                                indicatorName: indictorInfo.name
                            });
                        }

                    }
                    vm.saveData = cleanSelection;
                }

                // Clean selection
                vm.selected = {};
                vm.all = false;
            }

            function getContainers() {
                containerService.getContainersByCertification(company.id, vm.currentCertification.id).then(
                    function (data) {
                        vm.containers = data;
                        vm.container = null;
                    },
                    function (error) {
                        console.log(error);
                    }
                );
            }

            /**
             * Do the chante step code
             * @todo: cambiar nombre de la funcion
             * @param valid bool
             */
            vm.doChangeStep = function (valid) {
                if (vm.step == 2) {
                    // store current selection
                    getContainers();
                }
                else
                {
                    vm.storeSelection(); // Guarda cuando regresa a la seleccion de certificacion y oficina
                    vm.valid = valid; // Allow skip certification
                }
                vm.indicators = [];
            }

            vm.removeFromIndicatorsByContainer = function (container) {
                vm.saveData = vm.saveData.filter(function(indicator){
                    return indicator.container != container.id;
                });
                updateSelectedList();
            }

            vm.removeFromIndicators = function(removeItem)
            {
                vm.saveData = vm.saveData.filter(function(indicator){
                    return indicator.indicator != removeItem.indicator;
                });

                // Remove the indicator from the list and not the container this allow to keep open the accordion
                var container = vm.displaySelected.find(function(item){return item.id == removeItem.container;});
                container.indicators = container.indicators.filter(function(indicator){
                    return indicator.indicator != removeItem.indicator;
                });
                // Update complete list when indocators are empty
                if(container.indicators.length == 0)
                    updateSelectedList();
            }

            function pantallaPermisos() {

                vm.step = 2; // Ya no es un wizard @todo crear cambiar nombre y tipo de variable
                vm.doChangeStep(false);
            }

            function back() {
                vm.step = 1;
                vm.doChangeStep(true);
                vm.lastContainer = null;
                updateSelectedList();
            }


            function save() {
                vm.storeSelection();
                $scope.close({$value: vm.saveData});
            }
        }
    });