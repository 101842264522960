angular.module('main')
        .directive('startCertification', function () {
            return {
                scope: {
                    resolve: '<',
                    close: '&',
                    dismiss: '&'
                },
                restrict: 'E',
                templateUrl: 'start-certification.tpl.html',
                controller: controller
            };

            function controller($scope, company, companyService, $state, user, userService) {
                var startedCertifications = [];

                init();

                function init() {
                    $scope.model = {};
                    $scope.save = save;
                    $scope.redirectTo = redirectTo;
                    $scope.certificationChange = certificationChange;
                    $scope.certifications = $scope.resolve.certifications;
                    $scope.key = $scope.resolve.key;
                    $scope.hasPermissions = hasPermissions;

                    loadStartedCertifications();
                    loadOffices();

                    $scope.years = [];
                    for(var i = 0; i < 16; i++){
                        $scope.years.push((new Date().getFullYear()+2)-i);
                    }
                      
                }

                function hasPermissions(roles)
                {
                    return userService.hasPermissions(user, roles);
                }

                function redirectTo(route) {
                    $state.go(route);
                    $scope.close();
                }

                function save() {
                    if(!$scope.model.certification || !$scope.model.office || !$scope.model.year) {
                        return;
                    }

                    companyService
                      .saveCompanyCertification({
                        certification: {
                          id: $scope.model.certification.id
                        },
                        office: {
                          id: $scope.model.office.id
                        },
                        year: $scope.model.year,
                        keepSynchronized:
                          $scope.model.keepSynchronized,
                        importedDataFrom: $scope.model
                          .importedDataFrom
                          ? parseInt(
                              $scope.model.importedDataFrom
                                .id
                            )
                          : null
                      })
                      .then(success, error);

                    function success(response) {
                        $state.go('certificationWizard', {id: response.id});
                        $scope.close();
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function loadOffices() {
                    companyService.findOffices().then(success, error);

                    function success(response) {
                        $scope.offices = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function loadStartedCertifications() {
                    companyService.findCompanyCertifications().then(success, error);
                           
                    function success(response) {
                        startedCertifications = response;
                    }

                    function error(error) {
                        console.log(error);
                    }
                }

                function certificationChange() {
                    $scope.startedCertifications = [];
                    for(var i = 0; i < startedCertifications.length; i++) {
                        if(startedCertifications[i].certification !== $scope.model.certification.name) {
                            continue;
                        }

                        $scope.startedCertifications.push(startedCertifications[i]);
                    }
                }
            }
        });