(function () {
    'use strict';

    angular
            .module('main')
            .controller('OfficesManagementController', OfficesManagementController);

    function OfficesManagementController(countryService, currencyService, companyService, user) {
        var vm = this;
        init();

        function init() {
            vm.offices = [{}];
            vm.save = save;
            vm.addOffice = addOffice;
            vm.removeOffice = removeOffice;
            vm.num = null;
            vm.user = user;
            loadCountries();
            loadCurrencies();
            loadPaises();
        }
        
        function save() {
            var index = 0;
            send();

            function send() {
                if(!(index < vm.offices.length)) {
                    vm.success = true;
                    return;
                }
                companyService.saveOffice(vm.offices[index])
                    .then(success)
                    .catch(error);
            }

            function success() {
                index++;
                send();
            }

            function error(error) {
                vm.error = true;
                console.log(error);
            }
        }

        function addOffice(n) {
            if (typeof n !== 'undefined') {
                for (var i = 0; i < n; i++) {
                    vm.offices.push({});
                }
                vm.num = null;
                return;
            }
            vm.offices.push({});
        }

        function removeOffice(index) {
            vm.offices.splice(index, 1);
        }

        function loadCountries() {
            countryService.findAll()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.countries = response;
            }

            function error(error) {
                console.log(error);
            }
        }
        
        function loadCurrencies() {
            currencyService.findAll()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.currencies = response;
            }

            function error(error) {
                console.log(error);
            }
        }

        function loadPaises() {
            countryService.findAllP()
                    .then(success)
                    .catch(error);

            function success(response) {
                vm.paises = response;
                vm.ciudades = [];
            }

            function error(error) {
                console.log(error);
            }
        }

    }

})();

