(function () {
    'use strict';

    angular
        .module('main')
        .service('userService', userService);

    function userService($q, $http, $state, user, company) {

        return { 
            login: login,
            loginLaravel:loginLarvel,
            getToken: getToken,
            getRefreshToken: getRefreshToken,
            getCompanies: getCompanies,
            changePassword: changePassword,
            resetPassword: resetPassword,
            logout: logout,
            hasPermissions: hasPermissions,
            apiRoute: apiRoute
        };

        function login(username, password) {
            var clientId     = '1_59fmlmwdky4ooow4sogk0oow8s4w8kcg0cc08wkw4sgwc8oswg';
            var clientSecret = '13xknv82r04gckc448sgo0wkcw884o0g4s8oc4wwgkso8w8c4g';
            var request = {
                url: '/oauth/v2/token',
                method: 'POST',
                data: 'client_id=' + clientId +
                '&client_secret=' + clientSecret +
                '&grant_type=password' +
                '&username=' + username +
                '&password=' + password,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            };
            var deferred = $q.defer();

            $http(request).then(success, error);

            function success(response) {
                window.localStorage.setItem('token', response.data['access_token']);
                window.localStorage.setItem('refreshToken', response.data['refresh_token']);
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        function loginLarvel(username, password) {
            var request = {
                url: routeService.apiRoute()+'/users/login',
                method: 'POST',
                data: 'grant_type=password' +
                '&username=' + username +
                '&password=' + password,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            };
            var deferred = $q.defer();

            $http(request).then(success, error);

            function success(response) {
                window.localStorage.setItem('token', response.data['access_token']);
                window.localStorage.setItem('refreshToken', response.data['refresh_token']);
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function getToken () {
            return window.localStorage.getItem('token');
        }
        
        function getRefreshToken () {
            return window.localStorage.getItem('refreshToken');
        }
        
        function getCompanies() {
            var deferred = $q.defer();

            $http.get('/api/me/companies').then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function changePassword(model) {
            var deferred = $q.defer();

            $http.post('/api/me/change-password', model).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }
        
        function resetPassword(model) {
            var deferred = $q.defer();

            $http.post('/api/users/password/reset', model, {
                headers: {skipAuthorization: true}
            }).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function logout() {
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('refreshToken');
            resetUser();
            resetCompany();
            $state.go('login', {}, {reload: true});
        }

        function resetUser() {
            user.id = '';
            user.email = null;
            user.fullName = null;
            user.jobPosition = null;
            user.roles = null;
        }

        function resetCompany() {
            company.id = '';
            company.name = '';
            company.dni = null;
            company.description = null;
            company.numberOfEmployees = null;
            company.classificationBySize = null;
            company.sector = null;
            company.industry = null;
            company.mainCountry = null;
            company.countries = null;
            company.updatedAt = null;
            company.availableCertifications = null;
            company.logo = null;
        }

        function hasPermissions(user, roles)
        {
            if(typeof roles === 'string' && (typeof user.roles !== 'undefined') && user.roles != null) {
                return user.roles.indexOf(roles) !== -1;
            }

            if((typeof user.roles !== 'undefined') && user.roles && typeof roles !== 'string') {
                return user.roles.filter(function (role) {
                    return roles.indexOf(role) !== -1;
                }).length > 0;
            }

            return false;
        }

        function apiRoute() {
            return 'http://susty-backend-laravel.test:90/api/';
        }
    }
})();