angular.module('main')
        .directive('echart', function () {
            return {
                scope: {
                    indicator: '=',
                    id: '='
                },
                restrict: 'E',
                replace: true,
                templateUrl: 'echart.tpl.html',
                controller: controller,
                controllerAs: 'vm',
                bindToController: true
            };

            function controller() {
                var vm = this;
              
                const labelName = [];
                const labelTotal = [];
                const indicators = [];
                const indicatorsTotal = [];
                
               if (vm.indicator.showGraphGroup) {
                document.getElementById('chart-'+vm.id).style.display = "block";
               }else{
                document.getElementById('chart-'+vm.id).style.display = "none";
               }
               var chartDom = document.getElementById('echart-'+vm.id);
               var  myChart = echarts.init(chartDom);

            
              

               if (vm.indicator.type === 'MONTHLY_FORM') {
                  const subTotal = [];
                vm.indicator.answer.lines.forEach(function(response) {
                  const series = [];

                  subTotal[0] =   typeof subTotal[0] === 'undefined' ? 0 + parseInt(defaultValue(response.january)) : subTotal[0] + parseInt(defaultValue(response.january));
                  subTotal[1] = typeof subTotal[1] === 'undefined' ? 0 + parseInt(defaultValue(response.february)) : subTotal[1] + parseInt(defaultValue(response.february));
                  subTotal[2] = typeof subTotal[2] === 'undefined' ? 0 + parseInt(defaultValue(response.march)) : subTotal[2] + parseInt(defaultValue(response.march));
                  subTotal[3] = typeof subTotal[3] === 'undefined' ? 0 + parseInt(defaultValue(response.april)) : subTotal[3] + parseInt(defaultValue(response.april));
                  subTotal[4] =typeof subTotal[4] === 'undefined' ? 0 + parseInt(defaultValue(response.may)) : subTotal[4] + parseInt(defaultValue(response.may));
                  subTotal[5] = typeof subTotal[5] === 'undefined' ? 0 + parseInt(defaultValue(response.june)) : subTotal[5] + parseInt(defaultValue(response.june));
                  subTotal[6] =  typeof subTotal[6] === 'undefined' ? 0 + parseInt(defaultValue(response.july)) : subTotal[6] + parseInt(defaultValue(response.july));
                  subTotal[7] = typeof subTotal[7] === 'undefined' ? 0 + parseInt(defaultValue(response.august)) : subTotal[7] + parseInt(defaultValue(response.august));
                  subTotal[8] = typeof subTotal[8] === 'undefined' ? 0 + parseInt(defaultValue(response.september)) : subTotal[8] + parseInt(defaultValue(response.september));
                  subTotal[9] = typeof subTotal[9] === 'undefined' ? 0 + parseInt(defaultValue(response.october)) : subTotal[9] + parseInt(defaultValue(response.october));
                  subTotal[10] = typeof subTotal[10] === 'undefined' ? 0 + parseInt(defaultValue(response.november)) : subTotal[10] + parseInt(defaultValue(response.november));
                  subTotal[11] = typeof subTotal[11] === 'undefined' ? 0 + parseInt(defaultValue(response.december)) : subTotal[11] + parseInt(defaultValue(response.december));
                   series.push(defaultValue(response.january));
                   series.push(defaultValue(response.february));
                   series.push(defaultValue(response.march));
                   series.push(defaultValue(response.april));
                   series.push(defaultValue(response.may));
                   series.push(defaultValue(response.june));
                   series.push(defaultValue(response.july));
                   series.push(defaultValue(response.august));
                   series.push(defaultValue(response.september));
                   series.push(defaultValue(response.october));
                   series.push(defaultValue(response.november));
                   series.push(defaultValue(response.december));
                   indicators.push(series);
                   labelName.push(response.item.summary+' consumo');
                });
                indicatorsTotal.push(subTotal);
                labelTotal.push('Total Consumo');

                const subTotalC = [];
                vm.indicator.answer.linesCosts.forEach(function(response) {
                
                  const series = [];
                  subTotalC[0] =   typeof subTotalC[0] === 'undefined' ? 0 + parseInt(defaultValue(response.january)) : subTotalC[0] + parseInt(defaultValue(response.january));
                  subTotalC[1] = typeof subTotalC[1] === 'undefined' ? 0 + parseInt(defaultValue(response.february)) : subTotalC[1] + parseInt(defaultValue(response.february));
                  subTotalC[2] = typeof subTotalC[2] === 'undefined' ? 0 + parseInt(defaultValue(response.march)) : subTotalC[2] + parseInt(defaultValue(response.march));
                  subTotalC[3] = typeof subTotalC[3] === 'undefined' ? 0 + parseInt(defaultValue(response.april)) : subTotalC[3] + parseInt(defaultValue(response.april));
                  subTotalC[4] =typeof subTotalC[4] === 'undefined' ? 0 + parseInt(defaultValue(response.may)) : subTotalC[4] + parseInt(defaultValue(response.may));
                  subTotalC[5] = typeof subTotalC[5] === 'undefined' ? 0 + parseInt(defaultValue(response.june)) : subTotalC[5] + parseInt(defaultValue(response.june));
                  subTotalC[6] =  typeof subTotalC[6] === 'undefined' ? 0 + parseInt(defaultValue(response.july)) : subTotalC[6] + parseInt(defaultValue(response.july));
                  subTotalC[7] = typeof subTotalC[7] === 'undefined' ? 0 + parseInt(defaultValue(response.august)) : subTotalC[7] + parseInt(defaultValue(response.august));
                  subTotalC[8] = typeof subTotalC[8] === 'undefined' ? 0 + parseInt(defaultValue(response.september)) : subTotalC[8] + parseInt(defaultValue(response.september));
                  subTotalC[9] = typeof subTotalC[9] === 'undefined' ? 0 + parseInt(defaultValue(response.october)) : subTotalC[9] + parseInt(defaultValue(response.october));
                  subTotalC[10] = typeof subTotalC[10] === 'undefined' ? 0 + parseInt(defaultValue(response.november)) : subTotalC[10] + parseInt(defaultValue(response.november));
                  subTotalC[11] = typeof subTotalC[11] === 'undefined' ? 0 + parseInt(defaultValue(response.december)) : subTotalC[11] + parseInt(defaultValue(response.december));
                  series.push(defaultValue(response.january));
                  series.push(defaultValue(response.february));
                  series.push(defaultValue(response.march));
                  series.push(defaultValue(response.april));
                  series.push(defaultValue(response.may));
                  series.push(defaultValue(response.june));
                  series.push(defaultValue(response.july));
                  series.push(defaultValue(response.august));
                  series.push(defaultValue(response.september));
                  series.push(defaultValue(response.october));
                  series.push(defaultValue(response.november));
                  series.push(defaultValue(response.december));
                  indicators.push(series);
                  labelName.push(response.item.summary+' costos');
                });
                indicatorsTotal.push(subTotalC);
                labelTotal.push('Total Costos');



               }else{
                var posicion =0;
                var extraida = '';
                var count = 0;
                vm.indicator.answer.subAnswers.forEach(function(response) {
                  if (count < 2) {
                  const series = [];
                 
                  posicion = response.indicator.name.indexOf("año");
                  extraida = response.indicator.name.substring(posicion);

                   series.push(response.january);
                   series.push(response.february);
                   series.push(response.march);
                   series.push(response.april);
                   series.push(response.may);
                   series.push(response.june);
                   series.push(response.july);
                   series.push(response.august);
                   series.push(response.september);
                   series.push(response.october);
                   series.push(response.november);
                   series.push(response.december);
                   indicators.push(series);
                   labelName.push(extraida+' consumo');
                
                
                    
                  }
                  count = count +1;
                });
                var posicion =0;
                var extraida = '';
                var count = 0;
                vm.indicator.answer.costs.forEach(function(response) {
                  if (count < 2) {
                  const series = [];
                 
                  posicion = response.indicator.name.indexOf("año");
                  extraida = response.indicator.name.substring(posicion);

                   series.push(response.january);
                   series.push(response.february);
                   series.push(response.march);
                   series.push(response.april);
                   series.push(response.may);
                   series.push(response.june);
                   series.push(response.july);
                   series.push(response.august);
                   series.push(response.september);
                   series.push(response.october);
                   series.push(response.november);
                   series.push(response.december);
                   indicators.push(series);
                   labelName.push(extraida+' costos');
                
                
                    
                  }
                  count = count +1;
                });

                vm.indicator.formulas.forEach(function(formula) {
                  if (formula.type == "footer") {
                      labelTotal.push(formula.label);
                      const series = [];
                      series.push( Number(formula.january.replace(formula.symbol, '')));
                      series.push( Number(formula.february.replace(formula.symbol, '')));
                      series.push( Number(formula.march.replace(formula.symbol, '')));
                      series.push( Number(formula.april.replace(formula.symbol, '')));
                      series.push( Number(formula.may.replace(formula.symbol, '')));
                      series.push( Number(formula.june.replace(formula.symbol, '')));
                      series.push( Number(formula.july.replace(formula.symbol, '')));
                      series.push( Number(formula.august.replace(formula.symbol, '')));
                      series.push( Number(formula.september.replace(formula.symbol, '')));
                      series.push( Number(formula.october.replace(formula.symbol, '')));
                      series.push( Number(formula.november.replace(formula.symbol, '')));
                      series.push( Number(formula.december.replace(formula.symbol, '')));
                      indicatorsTotal.push(series);
                  }
                 
   
              });

               }


     
         



                
               valor = source();        
               myChart.setOption(
                {
                  
                  tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                      type: 'cross',
                      crossStyle: {
                        color: '#999'
                      }
                    }
                  },
                  legend: {},
                  toolbox: {
                    show: true,
                    feature: {
                      dataZoom: {
                        yAxisIndex: 'none'
                      },
                      dataView: { readOnly: false },
                      magicType: { type: ['line', 'bar'] },
                      restore: {},
                      saveAsImage: {}
                    },
                    orient: 'vertical',
                    right: 0,
                    top: 'center'
                  },
                  xAxis: {
                    type: 'category',
                    axisPointer: {
                      type: 'shadow'
                    },
                    data: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul','Ago', 'Set', 'Oct','Nov', 'Dec'],
                  },
                  yAxis: {
                    type: 'value',
                    axisLabel: {
                      formatter: '{value}'
                    }
                  },
                  series: valor
                } 
                );

                window.onresize = function() {
                  myChart.resize();
                };
              
                   

            function source() {
                const parent = [];
              

               for (var i = 0; i < labelName.length; i++) {
                    parent.push({
                        name: labelName[i],
                        type: 'bar',
                        tooltip: {
                          valueFormatter: function (value) {
                            return value + ' Sub';
                          }
                        },
                        data: indicators[i]
                });
             }
             for (var i = 0; i < labelTotal.length; i++) {             
                  parent.push({
                      name: labelTotal[i],
                      type: 'line',
                      markPoint: {
                        data: [
                          { type: 'max', name: 'Max' },
                          { type: 'min', name: 'Min' }
                        ]
                      },
                      tooltip: {
                        valueFormatter: function (value) {
                          return value + ' Total';
                        }
                      },
                      data: indicatorsTotal[i]
              });     
             }
                return parent; 
            }
                
       
               function defaultValue(params) {
                var valor = 0;
                typeof params === 'undefined' ? 0 : valor =params ;

                return valor;
               }

            
            }
        });