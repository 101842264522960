(function () {
    'use strict';

    angular.module('main', [
        'ui.router',
        'ngSanitize',
        'ui.select',
        'ngAnimate',
        'ngTouch',
        'ui.bootstrap',
        'as.sortable',
        'pascalprecht.translate',
        'angular-svg-round-progressbar',
        'angular.filter',
        'checklist-model',
        'nvd3',
        'monospaced.elastic',
        'infinite-scroll'
    ]);

})();