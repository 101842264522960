(function () {
    'use strict';
    angular
            .module('main')
            .controller('MainController', MainController);

    function MainController($uibModal, company,  $state, user, userService) {
        var vm = this;
        vm.openMenu = openMenu;
        vm.company = company;
        vm.redirectTo = redirectTo;
        vm.openComponent = openComponent;
        vm.user = user;
        vm.logout = logout;
        vm.hasPermissions = hasPermissions;
        vm.displayMenu = displayMenu;
        var contador = 1;
        vm.close = close;
        function close() {
            $state.reload();
        }
        function openMenu() {
            $uibModal.open({
                animation: true,
                component: 'menu',
                windowClass: 'right'
            });
        }

       

        function hasPermissions(roles)
        {
            return userService.hasPermissions(user, roles);
        }
        function displayMenu()
        {
          const nav =   document.getElementById('content-nav');
          const img =   document.getElementById('img-menu-mobile');
          if ( nav.style.display == 'block') {
            nav.style.display = 'none';
            img.src = '../../img/content/menu.png';
          }else{
            nav.style.display = 'block';
            img.src = '../../img/content/exit-white.png';
          }
        }

        function redirectTo(route) {
            $state.go(route);
        }

        function openComponent(name) {
            $uibModal.open({
                animation: true,
                component: name,
                windowClass: 'right'
            });
        }

        function logout() {
            userService.logout();
        }

        
    }


})();